import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { GatsbyImage } from 'gatsby-plugin-image'
import readingTime from 'reading-time'
import { BLOCKS } from '@contentful/rich-text-types'
import styled, { css } from 'styled-components'

import Seo from '../components/seo'
import Layout from '../components/layout'
import Hero from '../components/hero'
import * as styles from './blog-post.module.css'

const NavLi = styled.li`
  ${(props) =>
    props.type &&
    css`
      padding-left: ${props.type === BLOCKS.HEADING_2
        ? '15px'
        : '0px'};
      line-height: 1.5;
    `}
`

function getPlainTextFromHeader(contentNode) {
  return contentNode.reduce((acc, current) => {
    if (current.value) {
      if (current.marks.length) {
        return `${acc}<${current.marks[0].type}>${current.value}</${current.marks[0].type}>`
      } else {
        return acc + current.value
      }
    } else if (current.content){
      return acc + getPlainTextFromHeader(current.content);
    } else {
      return acc;
    }
  }, '')
}

function getHeadersFromRichText(richText) {
  const headers = (content) => [BLOCKS.HEADING_1, BLOCKS.HEADING_2].includes(content.nodeType)

  return JSON.parse(richText.raw).content.filter(headers)
    .map((heading) => {
      const plainText = getPlainTextFromHeader(heading.content);
      return {
        text: plainText,
        href: `#${heading.key}`,
        type: heading.nodeType,
      }
    })
}

const TableOfContents = ({ post }) => {
  return (
    <>
      <ol className={styles.nav}>
        {getHeadersFromRichText(post).map((item, i) => (
          <NavLi key={i} type={item.type}>
            <a href={item.href} dangerouslySetInnerHTML={{__html: item.text}} />
          </NavLi>
        ))}
      </ol>
    </>
  )
}

const options = {
  renderNode: {
    'embedded-asset-block': (node) => {
      const { gatsbyImageData } = node.data.target
      if (!gatsbyImageData) {
        // asset is not an image
        return null
      }
      return <GatsbyImage alt="" image={gatsbyImageData} />
    },
    [BLOCKS.HEADING_1]: (node, children) => {
      return <h1 id={node.key}>{children}</h1>
    },
    [BLOCKS.HEADING_2]: (node, children) => {
      return <h2 id={node.key}>{children}</h2>
    },
  },
}

class BlogPostTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.contentfulBlogPost')
    const previous = get(this.props, 'data.previous')
    const next = get(this.props, 'data.next')
    const plainTextDescription = documentToPlainTextString(
      JSON.parse(post.description.raw)
    )
    const plainTextBody = documentToPlainTextString(JSON.parse(post.body.raw))
    const content = JSON.parse(post.body.raw).content.map((item, i) => {
      if ([BLOCKS.HEADING_1, BLOCKS.HEADING_2].includes(item.nodeType)) {
        return {
          ...item,
          key: i
        }
      }
      return item
    })
    const body = {
      ...post.body,
      raw: JSON.stringify({
        ...post.body.raw,
        content
      })
    }
    const { minutes: timeToRead } = readingTime(plainTextBody)
    return (
      <Layout location={this.props.location}>
        <Seo
          title={post.title}
          description={plainTextDescription}
          image={`http:${post.heroImage.resize.src}`}
          keywords={post.keyword}
        />
        <Hero
          image={post.heroImage?.gatsbyImage}
          title={post.title}
          // content={post.description}
        />
        <div className={styles.container}>
          <span className={styles.meta}>
            {post.author?.name} &middot;{' '}
            <time dateTime={post.rawDate}>{post.publishDate}</time> –{' '}
            {timeToRead} minute read
          </span>
          <div className={styles.article}>
            <TableOfContents post={body} />
            <div className={styles.body}>
              {post.body?.raw && renderRichText(body, options)}
              {(previous || next) && (
                <nav>
                  <ul className={styles.articleNavigation}>
                    {previous && (
                      <li>
                        <Link to={`/blog/${previous.slug}`} rel="prev">
                          ← {previous.title}
                        </Link>
                      </li>
                    )}
                    {next && (
                      <li>
                        <Link to={`/blog/${next.slug}`} rel="next">
                          {next.title} →
                        </Link>
                      </li>
                    )}
                  </ul>
                </nav>
              )}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $slug: String!
    $previousPostSlug: String
    $nextPostSlug: String
  ) {
    contentfulBlogPost(slug: { eq: $slug }) {
      slug
      title
      keywords
      author {
        name
      }
      publishDate(formatString: "MMMM Do, YYYY")
      rawDate: publishDate
      heroImage {
        gatsbyImage(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
        resize(height: 630, width: 1200) {
          src
        }
      }
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData
          }
        }
      }
      description {
        raw
      }
    }
    previous: contentfulBlogPost(slug: { eq: $previousPostSlug }) {
      slug
      title
    }
    next: contentfulBlogPost(slug: { eq: $nextPostSlug }) {
      slug
      title
    }
  }
`
